import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ height: "100vh" }}>
      <h1 style={{ width: "100%", textAlign: "center", marginTop: "40vh" }}>
        Hi there explorer !! These are uncharted teritory
      </h1>
    </div>
  </Layout>
)

export default NotFoundPage
